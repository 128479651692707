@import 'app/common/styles/background-colors.module.scss';
@import 'app/common/styles/breakpoints.module.scss';
@import 'app/common/layouts/main-layout/variables';

.infoWrapper {
  background-color: $bg-island-inner;
  gap: 0.5rem;
  padding-block: 0.25rem;
  padding-inline: 0.75rem;
  border-radius: 0.5rem;
}

.header {
  width: 100%;
  position: sticky;
  inset-block-start: 0;
  inset-inline-end: 0;
  z-index: 100;
  background-color: $bg-island;
  box-shadow: $bg-island-shadow;
  padding-inline: 1rem;
  padding-block: 0.75rem;
  display: flex;
}
.content {
  max-width: $mediaMaxWidth;
  flex: 1;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hamburgerWrapper {
  display: none;

  @include lg-breakpoint {
    display: block;
  }

  @media screen and (min-width: $mediaMaxWidth) {
    display: none;
  }
}

.link {
  display: flex;
}
.profile {
  .divider {
    height: 1rem;
  }

  .balanceIcon {
    margin-inline-end: 0.25rem;
  }
}
