@import 'app/common/styles/text-colors.module.scss';
@import 'app/common/styles/background-colors.module';

.logout {
  color: $text-primary;
  opacity: 0.6;
  svg {
    fill: $text-primary;
  }
}

.link {
  cursor: pointer;
}

.notificationButton {
  background-color: $bg-island-inner;
  margin-inline-start: auto;
  padding: 0.375rem;
  border-radius: 0.25rem;
  overflow: hidden;
}
