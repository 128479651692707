@import 'app/common/styles/background-colors.module.scss';
@import 'app/common/styles/status-colors.module.scss';
@import 'app/common/styles/icon-colors.module.scss';
@import 'app/common/styles/breakpoints.module.scss';

.closeButton {
  position: absolute;
  transition: fill 0.3s;
  z-index: 1;
  cursor: pointer;

  &:hover {
    fill: $status-danger;
  }

  &:active {
    fill: $icon-primary;
  }
}

.closeButtonMobile {
  opacity: 0;
  inset-block-start: 1.625rem;
  inset-inline-end: 1rem;
  transition: opacity 0.2s;

  &.active {
    opacity: 1;
  }
}

.closeButtonDesktop {
  display: none;
}

.modalLayout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 35.5rem;
  position: relative;
  padding: 1.5rem 1rem;
  padding-block-end: 0;
  // animation: hide 0.3s linear forwards;
  backface-visibility: hidden;
  will-change: animation;
  cursor: default;

  // &.active {
  //   animation: show 0.3s linear forwards;
  // }

  .title {
    margin-inline-end: 2rem;
    white-space: break-spaces;
  }

  .contentWrapper {
    flex: 1;
    width: 100%;
    overflow-y: auto;
    overflow-x: clip;
    margin: 0 auto;
    margin-block-start: 2rem;
  }

  .reservedFooter {
    width: 100%;
    height: 8rem;
    min-height: 8rem;
  }

  &.large {
    max-width: 100%;
    width: 62.5rem;
  }
}

@include xl-breakpoint {
  .modalLayout {
    max-width: 100%;
    height: auto;
    border: 0.0625rem solid rgba(0, 0, 0, 0.08);
    background-color: $bg-island;
    box-shadow: $bg-island-shadow;
    border-radius: 1rem;
    padding: 2rem 1.5rem;
    display: block;

    .reservedFooter {
      display: none;
    }

    &.small {
      width: 28.125rem;
    }

    &.medium {
      width: 37.5rem;
    }

    .contentWrapper {
      padding-block-end: 0;
      overflow: unset;
    }

    .closeButtonDesktop {
      display: block;
      inset-block-start: 2.125rem;
      inset-inline-end: 1.5rem;
    }
  }
  .closeButtonMobile {
    display: none;
  }
}

@keyframes show {
  from {
    opacity: 0;
    transform: translateY(-125%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hide {
  from {
    opacity: 1;
    transform: translateY(10%);
  }
  100% {
    opacity: 0;
    transform: translateY(125%);
  }
}
