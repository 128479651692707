@import 'app/common/styles/background-colors.module.scss';

.hamburgerButton {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: $bg-island-inner;
  cursor: pointer;
}
